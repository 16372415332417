import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import questionData2023 from 'api/ehc/waps/2023/questionsData.json';
import surveyResponses2023 from 'api/ehc/waps/2023/sharePointItemsData.json';
import questionData2024 from 'api/ehc/waps/2024/questionsData.json';
import surveyResponses2024 from 'api/ehc/waps/2024/sharePointItemsData.json';

import ScrollToTopButton from 'components/ScrollToTopButton';
import QuestionRenderer from './QuestionRenderer';

const ProjectResponseDetailView = () => {
  const { id, year } = useParams();

  const questionData = year === '2024' ? questionData2024 : questionData2023;
  const surveyResponses =
    year === '2024' ? surveyResponses2024 : surveyResponses2023;

  const displayedQuestions = questionData.surveyoptions.filter(
    ({ questionShort }) =>
      ![
        'OtherTechnicalContact',
        'StartTime',
        'CompletionTime',
        'Email',
        'Name',
        'LastModified',
        'ProjectName',
        'DevAvgRating',
        'ReasonForRating'
      ].includes(questionShort[0])
  );

  const projectData = surveyResponses.find(
    ({ id: responseId }) => responseId === id
  );

  const { ProjectName, Name, Title, OtherTechnicalContact } = projectData;

  return (
    <div className='page-background'>
      <div className='container mx-auto pb-20'>
        <div className='flex flex-col gap-2 pt-32 pb-10'>
          <div className='inline-flex items-center gap-4 text-nortal-grey-dark'>
            <Link to={`/well-architected-projects-survey/${year}`}>
              <FaArrowLeft
                size={26}
                className='hover:cursor-pointer hover:text-nortal-green duration-200'
              />
            </Link>

            <h1 className='text-4xl font-bold'>{`${ProjectName} (${year})`}</h1>
          </div>
          <div className='flex flex-col gap-2 px-11'>
            <div className='inline-flex items-end gap-2'>
              <p className='text-xl'>Responder (technical contact):</p>
              <a
                href={`mailto:${Title}`}
                className='hover:underline text-nortal-green duration-200'
              >
                {Name}
              </a>
            </div>
            <p>Other technical contacts: {OtherTechnicalContact}</p>
          </div>
        </div>
        <div className='px-12 py-4 my-8 bg-white rounded-lg border'>
          <p className='text-2xl font-semibold text-nortal-grey-dark'>
            Table of contents:
          </p>
          <ul className='flex flex-col gap-2 my-6'>
            {displayedQuestions.map(({ question }, idx) => (
              <li
                className='text-nortal-green text-lg hover:underline list-disc'
                key={`menu-item-${idx}`}
              >
                <a href={`#question-${idx}`}>{question}</a>
              </li>
            ))}
          </ul>
        </div>
        {id && (
          <QuestionRenderer
            responses={projectData}
            questions={displayedQuestions}
          />
        )}
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default ProjectResponseDetailView;
