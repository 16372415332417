import CustomizedLabel from './CustomizedLabel';
import BarChartCustomShape from './BarChartCustomShape';
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { truncateIfLong } from 'util/renderingUtils';
import useIsMobile from 'util/hooks/useIsMobile';

const CustomToolTip = ({
  active,
  payload: [{ payload: { answer, label } = {} } = {}] = [],
  isPercentage,
  otherAnswersToolTip,
  otherAnswersToolTipPercentage,
  answeredByTooltip
}) =>
  active &&
  answer &&
  (answer !== label ||
    (answer === 'Other' && otherAnswersToolTip.length > 0) ||
    answeredByTooltip[answer]) && (
    <div className='bg-nortal-grey-base px-4 py-1 text-sm text-black rounded-md m-auto text-left'>
      <p className='text-base'>{`${answer}`}</p>
      {answeredByTooltip[answer] && (
        <>
          <p>Responders:</p>
          {answeredByTooltip[answer].map((responder) => (
            <p className='text-nortal-green' key={responder}>
              {responder}
            </p>
          ))}
        </>
      )}
      {answer === 'Other' &&
        (isPercentage
          ? otherAnswersToolTipPercentage
          : otherAnswersToolTip
        ).map((txt) => (
          <p className='text-nortal-green' key={txt}>
            {txt}
          </p>
        ))}
    </div>
  );

const QuestionBarChart = ({
  data,
  maxValue,
  isPercentage,
  otherAnswersToolTip,
  otherAnswersToolTipPercentage,
  answeredByTooltip
}) => {
  const isMobile = useIsMobile();
  const needsBiggerSpacing = data.some(({ answer }) => answer.length > 70);

  const truncatedData = data.map(({ answer, count }) => ({
    answer,
    count,
    label: truncateIfLong(answer, 60)
  }));

  return (
    <ResponsiveContainer
      height={
        truncatedData.length *
        (isMobile
          ? needsBiggerSpacing
            ? 90
            : 65
          : needsBiggerSpacing
          ? 70
          : 50)
      }
    >
      <BarChart
        layout='vertical'
        margin={isMobile ? { right: 41, top: 20 } : { right: 0 }}
        data={truncatedData}
        barCategoryGap={isMobile ? 26 : needsBiggerSpacing ? 20 : 10}
      >
        <XAxis type='number' tickCount={100} hide domain={[0, maxValue]} />
        <YAxis
          mirror={isMobile}
          dy={
            isMobile
              ? needsBiggerSpacing
                ? -50
                : -23
              : needsBiggerSpacing
              ? -5
              : 0
          }
          hide
          type='category'
          stroke='black'
          dataKey='answer'
          axisLine={false}
          tickLine={false}
          tick={{
            fill: '#53565A',
            fontSize: isMobile ? 14 : 18,
            fontWeight: 700,
            textAnchor: 'start'
          }}
          dx={isMobile ? 10 : -210}
          width={isMobile ? 230 : 220}
        />
        <Tooltip
          isPercentage={isPercentage}
          content={
            <CustomToolTip
              otherAnswersToolTip={otherAnswersToolTip}
              otherAnswersToolTipPercentage={otherAnswersToolTipPercentage}
              answeredByTooltip={answeredByTooltip}
            />
          }
          cursor={false}
        />
        <Bar
          dataKey='count'
          isAnimationActive={false}
          shape={<BarChartCustomShape isMobile={isMobile} />}
          label={
            <CustomizedLabel
              data={truncatedData}
              isMobile={isMobile}
              isPercentage={isPercentage}
              isValueDisplayed={true}
              customWidth={260}
            />
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
export default QuestionBarChart;
