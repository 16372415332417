import { Fragment } from 'react';

import MultipleAnswerBlock from 'components/MultipleAnswerBlock';
import PlainTextBlock from 'components/PlainTextBlock';
import SingleAnswerBlock from 'components/SingleAnswerBlock';

const QuestionRenderer = ({ responses, questions }) => {
  return (
    <div className='p-6 flex flex-col gap-4 bg-white border rounded-lg'>
      {questions.map(
        (
          {
            question,
            displayInfo: { questionType, options, scale },
            questionShort,
            description
          },
          idx
        ) => {
          const shortQuestion = questionShort[0];
          const answer = responses[shortQuestion];
          return (
            <Fragment key={`question-${idx}`}>
              {questionType === 'categorical' && (
                <SingleAnswerBlock
                  description={description}
                  question={question}
                  options={options}
                  answer={answer}
                  index={idx + 1}
                />
              )}
              {questionType === 'multiCategorical' && (
                <MultipleAnswerBlock
                  description={description}
                  question={question}
                  options={options}
                  answer={answer}
                  index={idx + 1}
                />
              )}
              {['OtherComments', 'ReasonForRating'].includes(shortQuestion) && (
                <PlainTextBlock
                  description={description}
                  question={question}
                  answer={answer}
                  index={idx + 1}
                />
              )}
            </Fragment>
          );
        }
      )}
    </div>
  );
};

export default QuestionRenderer;
