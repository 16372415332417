import { graphConfig } from './authConfig';

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */

export async function getColumns(accessToken) {
  return (await callMsGraph(accessToken, graphConfig.graphColumnEndpoint))
    .columns;
}

export async function getItems(accessToken) {
  let items = [];
  let response = await callMsGraph(accessToken, graphConfig.graphItemsEndpoint);
  while (response !== undefined) {
    items = items.concat(response.value);
    let nextLink = response['@odata.nextLink'];
    if (nextLink === undefined) {
      break;
    }
    response = await callMsGraph(accessToken, nextLink);
  }

  return items.map((item) => item.fields);
}

async function callMsGraph(accessToken, endpoint) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch(endpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
