import React from 'react';
import { useMsal } from '@azure/msal-react';

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      });
    }
  };

  return (
    <button
      className='text-sm text-white md:text-base'
      onClick={() => handleLogout('redirect')}
    >
      Sign out
    </button>
  );
};
