import { useEffect, useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import clsx from 'clsx';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const btnClasses = clsx(
    'p-3 bg-nortal-green fixed md:bottom-10 md:right-20 bottom-5 right-5 shadow-md hover:scale-125 transition-all ease-in-out duration-300',
    {
      hidden: !isVisible,
      block: isVisible
    }
  );

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <button onClick={scrollToTop} className={btnClasses}>
      <FaArrowUp className='text-white' />
    </button>
  );
};

export default ScrollToTopButton;
