import Heading from 'components/Heading';
import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className='flex flex-col h-screen justify-center pt-20 text-center gap-4'>
      <Heading>Hey! We couldn't find the page you are looking for!</Heading>
      <Heading level={4}>
        Here is a way back{' '}
        <Link className='underline text-nortal-green' to='/'>
          home
        </Link>
      </Heading>
    </div>
  );
};

export default ErrorPage;
