import clsx from 'clsx';
import { useState } from 'react';

import PropTypes from 'prop-types';
import useIsMobile from 'util/hooks/useIsMobile';
import { addColourForNumericalAttribute } from 'util/renderingUtils';

import { isCategorical } from 'questionsProvider';
import Heading from './Heading';
import { NavigationList } from './NavigationList';
import Paragraph from './Paragraph';
import PlainTextBlock from './PlainTextBlock';
import QuestionBarChart from './QuestionBarChart';
import QuestionStackedBarChart from './QuestionStackedBarChart';

const QuestionResult = ({
  question,
  dataResponses,
  displayInfo,
  dataPercentages,
  answered,
  categories,
  questionItems,
  otherAnswersToolTip,
  questionShort: [questionName] = [],
  otherAnswersToolTipPercentage,
  answeredByTooltip = {},
  uniqueAnsweredCount,
  answeredSecond,
  year
}) => {
  const isMobile = useIsMobile();
  const [visibleType, setVisibleType] = useState(
    answeredSecond ? 'number' : 'percentage'
  );
  const isTotalResponsesShown =
    process.env.REACT_APP_SHOW_TOTAL_RESPONSES === 'true';
  const { questionType, average, scale } = displayInfo;

  const responseCounterClasses = clsx('mx-1', {
    invisible: !isTotalResponsesShown
  });
  const responseButtonClasses = clsx('px-2 py-1 m-1 text-xs rounded-full ', {
    'bg-nortal-green text-white': visibleType === 'number'
  });
  const percentageButtonClasses = clsx('px-2 py-1 m-1 text-xs rounded-full', {
    'bg-nortal-green text-white': visibleType === 'percentage'
  });

  const categoricalAnswerCommonProps = {
    data: visibleType === 'number' ? dataResponses : dataPercentages,
    isPercentage: visibleType === 'percentage',
    answeredByTooltip
  };

  return (
    <div className='grid grid-cols-1 my-10 border-b-2'>
      <Heading level={4} fontWeight='black' className='lg:text-lg text-base'>
        {question}
      </Heading>

      <div className='mb-4 mt-8'>
        {questionType === 'unique' && (
          <NavigationList
            questionItems={questionItems}
            navigationKey={questionName}
            year={year}
          />
        )}
        {questionType === 'scale' && (
          <PlainTextBlock
            answer={`${average} / ${scale}`}
            className={clsx(
              'lg:text-base lg:font-semibold text-sm font-bold',
              addColourForNumericalAttribute(average / scale)
            )}
          />
        )}
        {questionType === 'categorical' && (
          <QuestionBarChart
            {...categoricalAnswerCommonProps}
            maxValue={visibleType === 'number' ? answered : 100}
            otherAnswersToolTip={otherAnswersToolTip}
            otherAnswersToolTipPercentage={otherAnswersToolTipPercentage}
            isMobile={isMobile}
          />
        )}
        {(questionType === 'multiCategorical' ||
          questionType === 'biCategorical') && (
          <QuestionStackedBarChart
            {...categoricalAnswerCommonProps}
            categories={categories}
            isMobile={isMobile}
          />
        )}
      </div>
      <div className='flex justify-between mb-2 items-center'>
        {!!(uniqueAnsweredCount || answered) && (
          <Paragraph size='sm' className={responseCounterClasses}>
            {uniqueAnsweredCount || answered} responders
          </Paragraph>
        )}

        {isCategorical(questionType) && (
          <div>
            <button
              className={percentageButtonClasses}
              onClick={() => setVisibleType('percentage')}
            >
              {'% Responses'}
            </button>
            <button
              className={responseButtonClasses}
              onClick={() => setVisibleType('number')}
            >
              {'# Responses'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

QuestionResult.protoTypes = {
  question: PropTypes.object.isRequired
};

export default QuestionResult;
