import { FaCheck } from 'react-icons/fa';
import clsx from 'clsx';

import Heading from './Heading';
import Paragraph from './Paragraph';

const Checkbox = ({ isSelected = false }) => {
  const backgroundClasses = clsx(
    'flex flex-col items-center justify-center rounded-sm border border-nortal-green w-4 h-4',
    {
      'bg-nortal-green': isSelected,
      'bg-white': !isSelected
    }
  );
  return (
    <div className={backgroundClasses}>
      <FaCheck size={10} className='text-white' />
    </div>
  );
};

const MultipleAnswerBlock = ({
  question,
  options,
  index,
  description,
  answer = '[]'
}) => {
  const answerAsArray = JSON.parse(answer.trim());

  const userSubmittedAnswer = answerAsArray.find(
    (answer) => !options.includes(answer)
  );

  return (
    <div className='flex flex-col gap-2 mb-6'>
      <div className='flex flex-col gap-2'>
        <Heading id={`question-${index}`} level={3} fontWeight='black'>
          {question}
        </Heading>
        {description && <Paragraph italic>{description}</Paragraph>}
      </div>
      <ul className='flex flex-col'>
        {options.map((option) => {
          const answerSelected =
            answerAsArray.find((answer) => answer === option) ||
            (option === 'Other' && userSubmittedAnswer);

          const textClasses = clsx('flex gap-2 items-center my-2', {
            'font-bold': answerSelected
          });
          return (
            <li key={option} className={textClasses}>
              <Checkbox isSelected={answerSelected} />
              {option === 'Other' && userSubmittedAnswer
                ? `Other - ${userSubmittedAnswer}`
                : option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MultipleAnswerBlock;
