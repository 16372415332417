import {FaFilePdf, FaExternalLinkSquareAlt} from 'react-icons/fa';
import Heading from './Heading';
import Paragraph from './Paragraph';

const Hero = ({ title, description, reportLinkText, reportFile, formLink, formLinkText }) => {
  return (
    <div className='pt-1 pb-4 sm:pt-16 sm:pb-6 flex flex-col '>
      <div className='lg:w-[61rem] m-auto pt-16'>
        <div className='flex flex-col px-6 sm:px-4'>
          <Heading className='py-3 uppercase' fontWeight='bold' id='hero-title'>
            {title}
          </Heading>
          <Paragraph size='xl'>{description}</Paragraph>
        </div>
        {formLink && formLinkText && (
          <a
            href={formLink}
            className='inline-flex gap-2 items-center my-6 mx-6 sm:mx-4'
            target='_blank'
            rel='noreferrer'
          >
            <FaExternalLinkSquareAlt className='text-nortal-grey-dark'/>
            <Paragraph size='lg' fontWeight='black'>
              {formLinkText}
            </Paragraph>
          </a>
        )}
        {reportFile && reportLinkText && (
          <a
            href={reportFile}
            className='inline-flex gap-2 items-center my-6 mx-6 sm:mx-4'
            target='_blank'
            rel='noreferrer'
          >
            <FaFilePdf className='text-nortal-grey-dark' />
            <Paragraph size='lg' fontWeight='black'>
              {reportLinkText}
            </Paragraph>
          </a>
        )}
      </div>
    </div>
  );
};

export default Hero;
