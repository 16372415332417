import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList
} from 'recharts';
import CustomizedLabel from './CustomizedLabel';
import { truncateIfLong } from 'util/renderingUtils';
import useIsMobile from 'util/hooks/useIsMobile';

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  if (rest.value === 0) {
    rest.value = '';
  } else if (rest.isPercentage) {
    rest.value += '%';
  }

  return <Label {...rest} fontSize='16' fill='#FFFFFF' fontWeight='SemiBold' />;
};

const CustomToolTip = ({
  active,
  payload,
  label,
  categories = ['Selected', 'Not Selected'],
  isPercentage,
  answeredByTooltip
}) => {
  const [cat1, cat2] = categories;
  const [val1, val2] = payload.filter((el) => el.fill !== 'none');
  if (active && payload && payload.length) {
    return (
      <div className='bg-nortal-grey-base w-auto h-auto px-4 py-1 text-sm text-black rounded-md m-auto text-left'>
        <p className='text-base'>{`${label}`}</p>
        {!answeredByTooltip[label] && (
          <>
            <p className='text-nortal-green'>{`${cat1} : ${val1.value}${
              isPercentage ? '%' : ''
            }`}</p>
            <p className='text-nortal-grey-dark'>{`${cat2} : ${val2.value}${
              isPercentage ? '%' : ''
            }`}</p>
          </>
        )}
        {answeredByTooltip[label] && (
          <>
            <p className='accent-nortal-grey-dark'>Responders:</p>
            {answeredByTooltip[label].map((responder) => (
              <p className='text-nortal-green' key={responder}>
                {responder}
              </p>
            ))}
          </>
        )}
      </div>
    );
  }

  return null;
};

export const QuestionStackedBarChart = ({
  data,
  isPercentage,
  categories,
  answeredByTooltip
}) => {
  const isMobile = useIsMobile();
  //Coefficient ensures same length for first invisible bar,
  //that serves as margin for visible bars without being a dead-zone for tooltip
  const correctionForDifferentCounts = 5 / 12;
  const truncatedData = data.map(({ answer, count, secondAnswerCount }) => ({
    answer,
    count,
    secondAnswerCount,
    leftMargin: (count + secondAnswerCount) * correctionForDifferentCounts,
    label: truncateIfLong(answer, 30)
  }));

  return (
    <div className='content c-white'>
      <ResponsiveContainer
        height={truncatedData.length * (isMobile ? 80 : 50)}
        width={isMobile ? '100%' : '90%'}
      >
        <BarChart
          layout='vertical'
          data={truncatedData}
          barCategoryGap={isMobile ? 25 : 10}
          margin={{ top: 10 }}
          stackOffset='expand'
        >
          <XAxis hide type='number' />
          <YAxis
            type='category'
            mirror={isMobile}
            dy={isMobile ? -32 : 0}
            dataKey='answer'
            stroke='black'
            tick={{
              fill: '#53565A',
              fontSize: isMobile ? 14 : 18,
              fontWeight: 700,
              textAnchor: 'start'
            }}
            axisLine={false}
            tickLine={false}
            hide
            dx={isMobile ? 8 : -190}
            width={150}
          />
          <Tooltip
            isPercentage={isPercentage}
            categories={categories}
            content={<CustomToolTip answeredByTooltip={answeredByTooltip} />}
          />
          {!isMobile && (
            <Bar
              dataKey='leftMargin'
              fill='none'
              stackId='a'
              isAnimationActive={false}
            />
          )}
          <Bar
            dataKey='count'
            fill='#01973A'
            stackId='a'
            isAnimationActive={false}
            radius={[15, 15, 15, 15]}
            style={{ stroke: '#fff', strokeWidth: 2 }}
            label={
              <CustomizedLabel
                data={truncatedData}
                isMobile={isMobile}
                isPercentage={isPercentage}
                customWidth={210}
              />
            }
          >
            <LabelList
              dataKey='count'
              position='center'
              isPercentage={isPercentage}
              content={renderCustomizedLabel}
            />
          </Bar>
          <Bar
            dataKey='secondAnswerCount'
            fill='#535659'
            stackId='a'
            isAnimationActive={false}
            radius={[15, 15, 15, 15]}
            style={{ stroke: '#fff', strokeWidth: 2 }}
          >
            <LabelList
              dataKey='secondAnswerCount'
              position='center'
              isPercentage={isPercentage}
              content={renderCustomizedLabel}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default QuestionStackedBarChart;
