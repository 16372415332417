import QuestionResult from './QuestionResult';

const ResultsCard = ({ questionItems, questionsWithAnswers, year }) => {
  return (
    <div className='w-[22rem] lg:w-[61rem] mx-auto bg-white border border-slate-300 rounded-md shadow-xl mb-4'>
      <div className='px-4'>
        {questionsWithAnswers.map((question, idx) => (
          <QuestionResult
            {...question}
            questionItems={questionItems}
            key={`question-${idx}`}
            year={year}
          />
        ))}
      </div>
    </div>
  );
};

export default ResultsCard;
