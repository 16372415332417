import clsx from 'clsx';

const Paragraph = ({
  size = 'base',
  fontWeight = 'regular',
  italic = false,
  className,
  children
}) => {
  const classes = clsx('text-nortal-grey-dark whitespace-pre-line', {
    'font-roboto-bold': fontWeight === 'bold',
    'font-roboto-black': fontWeight === 'black',
    'font-roboto-regular': fontWeight === 'regular',
    'text-sm font-roboto-regular': size === 'sm',
    'text-base font-roboto-regular': size === 'base',
    'text-lg leading-7': size === 'lg',
    'sm:text-[1.375rem] sm:leading-7 text-lg leading-6': size === 'xl',
    italic: italic,
    [className]: !!className
  });
  return <p className={classes}>{children}</p>;
};

export default Paragraph;
