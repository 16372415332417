import clsx from 'clsx';

export const addColourForNumericalAttribute = (normalisedValue) =>
  clsx({
    'text-red-600': normalisedValue < 0.2,
    'text-orange-600': normalisedValue >= 0.2 && normalisedValue < 0.4,
    'text-yellow-600': normalisedValue >= 0.4 && normalisedValue < 0.6,
    'text-green-400': normalisedValue >= 0.6 && normalisedValue < 0.8,
    'text-nortal-green': normalisedValue >= 0.8
  });
export const truncateIfLong = (answer, maxLength ) => {
    if (answer.length > maxLength) {
        const nextSpace = answer.indexOf(' ', maxLength - 10);
        if (nextSpace === -1) {
            return answer;
        }
        return answer.slice(0, nextSpace) + '...';
    }
    return answer;
};
