const BarChartCustomShape = ({ x, y, width, isMobile }) => {
  const numberToResizeBarToFitInBorder = isMobile ? 1.125 : 0.333;
  const barOffSet = isMobile ? 0 : 350;
  const xCoordWithOffset = x + barOffSet;
  return (
    <svg className='fill-nortal-green'>
      <rect
        x={xCoordWithOffset}
        y={y}
        width={width * numberToResizeBarToFitInBorder}
        className='h-[1.438rem]'
      />
      <path
        className='fill-white'
        transform={`translate(${xCoordWithOffset},${y} )`}
        d='M 0.00390625 0.0078125 L 0.00390625 24.007812 L 314.00391 24.007812 L 314.00391 0.0078125 L 0.00390625 0.0078125 z M 12 1 L 302 1 C 308.09399 1 313 5.9060061 313 12 C 313 18.093994 308.09399 23 302 23 L 12 23 C 5.9060061 23 1 18.093994 1 12 C 1 5.9060061 5.9060061 1 12 1 z '
      />

      <rect
        className='stroke-nortal-green stroke-2 h-[1.375rem] w-[19.5rem] '
        x={xCoordWithOffset + 1}
        y={y + 1}
        fillOpacity={0}
        rx={11}
      />
    </svg>
  );
};

export default BarChartCustomShape;
