import { Text } from 'recharts';

const CustomizedLabel = ({
  x,
  y,
  data,
  index,
  height,
  customWidth,
  value,
  isMobile,
  isPercentage,
  isValueDisplayed = false
}) => {
  return (
    <>
      <Text
        fill='#53565A'
        width={customWidth}
        fontSize={isMobile ? 14 : 18}
        fontWeight={700}
        x={isValueDisplayed ? x : 0}
        y={y + (isMobile ? -5 : height)}
        dx={6}
        dy={isMobile ? -12 : -20}
        textAnchor='start'
        verticalAnchor='middle'
      >
        {data[index].label}
      </Text>
      {isValueDisplayed && (
        <Text
          fill='#53565A'
          fontSize={isMobile ? 14 : 18}
          fontWeight={700}
          width={150}
          x={x + (isMobile ? 290 : 900)}
          y={y + (isMobile ? 0 : height)}
          dx={6}
          dy={isMobile ? -12 : -10}
          textAnchor='end'
        >
          {value + (isPercentage ? '%' : '')}
        </Text>
      )}
    </>
  );
};

export default CustomizedLabel;
