import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const NavigationList = ({ questionItems, navigationKey, year }) => {
  const sortedItems = questionItems
    .map((item) => ({ id: item.id, label: item[navigationKey] }))
    .sort(({ label: a }, { label: b }) => a.localeCompare(b));
  return (
    <ul>
      {sortedItems.map(({ id, label }) => (
        <li key={`nav-${id}`} className='pb-2'>
          <Link
            to={`/well-architected-projects-survey/project/${id}/${year}`}
            className='text-nortal-green'
          >
            <span className='underline text-base font-semibold text-left'>
              {label}
            </span>{' '}
            <FaExternalLinkSquareAlt className='inline-block text-xs' />
          </Link>
        </li>
      ))}
    </ul>
  );
};
