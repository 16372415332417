import clsx from 'clsx';

const TeamMember = ({ name, role, className = '' }) => {
  const wrapperClasses = clsx(className, 'text-center');
  return (
    <div className={wrapperClasses}>
      <h3 className='text-xl sm:text-2xl my-3'>{name}</h3>
      <p className='text-lg sm:text-xl text-nortal-grey-light'>{role}</p>
    </div>
  );
};

export default TeamMember;
