import clsx from 'clsx';

import Heading from './Heading';
import Paragraph from './Paragraph';

const PlainTextBlock = ({
  question,
  answer,
  description,
  className = '',
  ...props
}) => {
  const textClasses = clsx(className, { italic: !answer });
  return (
    <div className='flex flex-col gap-4 mb-6' {...props}>
      <div className='flex flex-col gap-2'>
        {question && (
          <Heading level={3} fontWeight='black'>
            {question}
          </Heading>
        )}
        {description && <Paragraph italic>{description}</Paragraph>}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: !answer ? 'No response' : answer }}
        className={textClasses}
      />
    </div>
  );
};

export default PlainTextBlock;
