//Extends provided question data with generic attributes (answered, answeredSecond, index),
// while displayInfo of every question is extended based on its type
export const getQuestionsDataForGeneralView = (
  { surveyoptions },
  questionItems
) =>
  surveyoptions
    .filter(({ displayInfo: { questionType } }) => questionType !== 'meta')
    .map((question, i) => ({
      ...question,
      answered: 0,
      answeredSecond: 0,
      key: i,
      displayInfo: extendDisplayInfoByType(question, questionItems)
    }));

export const getCategoricalQuestionForShowingInChart = (
  question,
  extraProps
) => {
  const { answered, answeredSecond } = getAnsweredCountForQuestions(question);
  sortAnswersByCount(question);
  const questionInChartAxis = mapQuestionToChartAxis(question);
  const questionInPercentageInChartAxis = mapQuestionInPercentageToChartAxis(
    question,
    answered,
    answeredSecond
  );
  return getQuestionWithData(
    question,
    questionInChartAxis,
    questionInPercentageInChartAxis,
    { ...extraProps, answered, answeredSecond }
  );
};

const extendDisplayInfoByType = (
  {
    questionShort: [questionShort],
    displayInfo: { questionType, scale, options, categories }
  },
  questionItems
) => {
  switch (questionType) {
    case 'biCategorical':
      return {
        questionType,
        categories,
        answers: Object.fromEntries(
          options
            .filter((option) => option !== 'Other')
            .map((option) => [option, { count: 0, secondAnswerCount: 0 }])
        )
      };
    case 'categorical':
    case 'multiCategorical':
      return {
        questionType,
        categories,
        answers: Object.fromEntries(
          options
            .filter((option) => option !== 'Other')
            .map((option) => [option, 0])
        )
      };
    case 'scale':
      return {
        questionType,
        scale,
        average: 0
      };
    case 'unique':
      return {
        questionType,
        list: questionItems.map((question) => ({
          id: question.id,
          name: question[questionShort]
        }))
      };
    default:
      return {};
  }
};

const sortAnswersByCount = (question) =>
  question.answers.sort(
    (
      { count: countA, secondAnswerCount: secondAnswerCountA },
      { count: countB, secondAnswerCount: secondAnswerCountB }
    ) =>
      countA === countB
        ? secondAnswerCountA - secondAnswerCountB
        : countB - countA
  );

const mapQuestionToChartAxis = (question) =>
  question.answers.map(({ count, secondAnswerCount, answer }) => ({
    answer,
    count,
    secondAnswerCount
  }));

const mapQuestionInPercentageToChartAxis = (
  question,
  answered,
  answeredSecond
) => {
  const { answers, questionShort, displayInfo } = question;
  const { questionType } = displayInfo;
  return answers.map(({ count, secondAnswerCount, answer }) => {
    const answersInTotal = count + secondAnswerCount;

    const isStacked =
      questionShort.length > 1 || questionType === 'multiCategorical';

    // if bar is stacked, calculate percentage between two values
    const answersTotal = isStacked ? answersInTotal : answered;
    const secondAnswersTotal = isStacked ? answersInTotal : answeredSecond;

    const secondAnswerCountInPercentages = convertFractionToPercentage(
      secondAnswerCount / secondAnswersTotal
    );
    return {
      answer,
      count: convertFractionToPercentage(count / answersTotal),
      ...(!isNaN(secondAnswerCountInPercentages) && {
        secondAnswerCount: secondAnswerCountInPercentages
      })
    };
  });
};

const getQuestionWithData = (
  { question },
  questionsResponseData,
  questionsPercentageData,
  additionalProps
) => ({
  question,
  dataResponses: questionsResponseData,
  dataPercentages: questionsPercentageData,
  otherAnswersToolTip: [],
  otherAnswersToolTipPercentage: [],
  ...additionalProps
});

const getAnsweredCountForQuestions = (questionsDataWithAnswerCount) => {
  const { answers } = questionsDataWithAnswerCount;
  return answers.reduce(
    (acc, { count, secondAnswerCount }) => ({
      answered: acc.answered + count,
      answeredSecond: acc.answeredSecond + secondAnswerCount
    }),
    { answered: 0, answeredSecond: 0 }
  );
};

const convertFractionToPercentage = (fraction) =>
  Math.round((fraction * 100 + Number.EPSILON) * 100) / 100;
