import { useEffect, useState } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';

import useIsMobile from 'util/hooks/useIsMobile';

import logo from 'assets/NortalLogo.svg';
import { SignOutButton } from './SignOutButton';

const Navbar = () => {
  const isMobile = useIsMobile();
  const [menuVisible, setMenuVisible] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setMenuVisible(false);
  }, [pathname, setMenuVisible]);

  return (
    <div className='w-full py-1.5 z-10 bg-nortal-green fixed drop-shadow-lg'>
      <nav className='px-4 flex flex-col lg:flex-row'>
        <div className='inline-flex items-center'>
          {isMobile && (
            <HiOutlineMenu
              size={36}
              className='text-white hover:cursor-pointer mr-2'
              onClick={() => setMenuVisible(!menuVisible)}
            />
          )}
          <Link to='/'>
            <img src={logo} className='h-14 lg:h-16 ' alt='Nortal logo' />
          </Link>
        </div>
        {((isMobile && menuVisible) || !isMobile) && (
          <div className='flex flex-col lg:flex-row lg:items-center items-start gap-4 text-lg text-white lg:text-base py-4 lg:py-0 w-full lg:justify-between'>
            <div className='flex flex-col lg:flex-row gap-4'>
              <Link to='/'>2024</Link>
              <Link to='/2023'>2023</Link>
              <Link to='/well-architected-projects-survey'>
                Well architected projects
              </Link>
              <Link to='/dora-report'>DORA4 Report</Link>
              <Link to='/team'>Team</Link>
            </div>
            <SignOutButton />
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
