import clsx from 'clsx';

import Heading from './Heading';
import Paragraph from './Paragraph';

const AnswerBullet = ({ isSelected = false }) => {
  const bulletBorderClasses = clsx('border p-1 rounded-full', {
    'border-nortal-green': isSelected,
    'border-gray-500': !isSelected
  });
  const bulletClasses = clsx('w-2 h-2 rounded-full', {
    'border bg-nortal-green border-nortal-green': isSelected
  });
  return (
    <div className={bulletBorderClasses}>
      <div className={bulletClasses} />
    </div>
  );
};

const SingleAnswerBlock = ({
  question,
  options,
  answer,
  index,
  description
}) => {
  const isUserSubmittedAnswer = !options.includes(answer);

  return (
    <div className='flex flex-col gap-4 mb-6'>
      <div className='flex flex-col gap-2'>
        <Heading id={`question-${index}`} level={3} fontWeight='black'>
          {question}
        </Heading>
        {description && <Paragraph italic>{description}</Paragraph>}
      </div>
      <ul>
        {options.map((option) => {
          const isSelectedAnswer = isUserSubmittedAnswer
            ? option === 'Other'
            : option === answer;

          const optionText =
            option === 'Other' && isUserSubmittedAnswer
              ? `${option} - ${answer}`
              : option;
          const answerClasses = clsx('my-2 flex items-center gap-2', {
            'font-bold': isSelectedAnswer
          });
          return (
            <li key={`${option}`} className={answerClasses}>
              <AnswerBullet isSelected={isSelectedAnswer} />
              {optionText}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SingleAnswerBlock;
