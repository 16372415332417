import {
  getCategoricalQuestionForShowingInChart,
  getQuestionsDataForGeneralView
} from './questionsMapper';

export const getQuestionsWithAnswersForGeneralView = (data) =>
  pipe(data, getQuestionsDataWithAnswers, getAnswersWithSmallCountsMerged);

export const isCategorical = (questionType) =>
  ['categorical', 'multiCategorical', 'biCategorical'].includes(questionType);

//Sequentially applies functions to data, by feeding output of one function to the next function
const pipe = (initialData, ...operators) =>
  operators.reduce((currentData, op) => op(currentData), initialData);

const getQuestionsDataWithAnswers = ({
  questionsData,
  questionItems,
  aggregateResponders = false
}) =>
  getQuestionsDataForGeneralView(questionsData, questionItems).map(
    (question) => {
      const [questionName, secondQuestionName] = question.questionShort;
      const { displayInfo } = question;

      const uniqueAnsweredCount = questionItems.reduce(
        (acc, answerData) => (answerData[questionName] ? acc + 1 : acc),
        0
      );
      const sharedArguments = {
        questionItems,
        questionName,
        question,
        displayInfo,
        uniqueAnsweredCount
      };
      switch (displayInfo.questionType) {
        case 'unique':
          return handleUnique(sharedArguments);
        case 'multiCategorical':
        case 'categorical':
          return handleCategorical(sharedArguments, aggregateResponders);
        case 'biCategorical':
          return handleBiCategorical(sharedArguments, secondQuestionName);
        case 'scale':
          return handleScale(sharedArguments);
        default:
          return {};
      }
    }
  );

const handleUnique = ({ question, uniqueAnsweredCount }) => ({
  ...question,
  uniqueAnsweredCount
});

const handleCategorical = (
  { questionItems, questionName, question, displayInfo, uniqueAnsweredCount },
  aggregateResponders
) => {
  const { questionType, answers } = displayInfo;
  const transformToAnswerCountArray = (answerStatistics) =>
    Object.entries(answerStatistics)
      .filter(([_, count]) => count || questionType === 'multiCategorical')
      .map(([answer, count]) => ({
        answer,
        count,
        secondAnswerCount:
          questionType === 'multiCategorical' ? questionItems.length - count : 0
      }));

  const answeredByTooltip = aggregateResponders
    ? getResponders(questionItems, questionName, 'ProjectName')
    : {};

  const question1 = {
    ...question,
    answers: pipe(
      { questionItems, questionName, answers },
      populateWithAnswerCounts,
      transformToAnswerCountArray
    )
  };
  const additionalProps = {
    displayInfo: { questionType },
    answeredByTooltip,
    uniqueAnsweredCount
  };
  return getCategoricalQuestionForShowingInChart(question1, additionalProps);
};

const populateWithAnswerCounts = ({ questionItems, questionName, answers }) => {
  return questionItems
    .flatMap((item) =>
      item[questionName] ? splitAnswersToArray(item[questionName]) : []
    )
    .reduce((answerCounts, answer) => {
      if (!answerCounts[answer]) {
        answerCounts[answer] = 0;
      }
      answerCounts[answer]++;
      return answerCounts;
    }, answers);
};

const getResponders = (questionItems, questionName, responderKey) =>
  questionItems.reduce((acc, item) => {
    const name = item[responderKey];
    const answer = item[questionName];
    if (answer) {
      const values = splitAnswersToArray(answer);
      values.forEach((value) => {
        if (!acc[value]) {
          acc[value] = [];
        }
        acc[value].push(name);
      });
    }
    return acc;
  }, {});

const handleScale = (questionContext) => {
  const {
    questionItems,
    questionName,
    question,
    displayInfo,
    uniqueAnsweredCount
  } = questionContext;
  const sum = questionItems.reduce((acc, item) => acc + item[questionName], 0);
  return {
    ...question,
    displayInfo: {
      ...displayInfo,
      average: (sum / questionItems.length).toFixed(2)
    },
    uniqueAnsweredCount
  };
};

const handleBiCategorical = (
  { questionItems, questionName, question, displayInfo, uniqueAnsweredCount },
  secondQuestionName
) => {
  const { questionType, categories, answers } = displayInfo;
  const transformToAnswerCountArray = (answerStatistics) =>
    Object.entries(answerStatistics)
      .filter(([_, { count, secondAnswerCount }]) => count || secondAnswerCount)
      .map(([answer, { count, secondAnswerCount }]) => ({
        answer,
        count,
        secondAnswerCount
      }));

  const extraProps = {
    displayInfo: { questionType },
    categories,
    uniqueAnsweredCount
  };
  const questionWithAnswerCounts = {
    ...question,
    answers: pipe(
      { questionItems, questionName, secondQuestionName, answers },
      populateBiCategoricalWithAnswerCounts,
      transformToAnswerCountArray
    )
  };
  return getCategoricalQuestionForShowingInChart(
    questionWithAnswerCounts,
    extraProps
  );
};

const populateBiCategoricalWithAnswerCounts = ({
  questionItems,
  questionName,
  secondQuestionName,
  answers
}) => {
  const answersWithFirstCategorySet = questionItems
    .flatMap((item) => splitAnswersToArray(item[questionName]))
    .reduce((answerCounts, answer) => {
      if (!answerCounts[answer]) {
        answerCounts[answer] = { count: 0, secondAnswerCount: 0 };
      }
      answerCounts[answer].count++;
      return answerCounts;
    }, answers);

  return questionItems
    .flatMap((item) => splitAnswersToArray(item[secondQuestionName]))
    .reduce((answerCounts, answer) => {
      if (!answerCounts[answer]) {
        answerCounts[answer] = { count: 0, secondAnswerCount: 0 };
      }
      answerCounts[answer].secondAnswerCount++;
      return answerCounts;
    }, answersWithFirstCategorySet);
};

const splitAnswersToArray = (answerByFieldNameString) => {
  return !answerByFieldNameString
    ? []
    : answerByFieldNameString.includes('["') &&
      answerByFieldNameString.includes('"]')
    ? JSON.parse(answerByFieldNameString)
    : [answerByFieldNameString];
};

const getAnswersWithSmallCountsMerged = (questionsForShowingInChart) =>
  questionsForShowingInChart.map((question) => {
    const { dataResponses, dataPercentages, ...rest } = question;
    const { displayInfo, answeredSecond } = rest;

    if (answeredSecond > 0 || !isCategorical(displayInfo.questionType)) {
      return question;
    }

    const indices = getIndicesOfAnswersUnderOnePercent(question);
    const minAnswersUnderOnePercentForOther = 3;
    if (indices.length <= minAnswersUnderOnePercentForOther) {
      return question;
    }
    //Indices are reversed for splice in updateData not to affect unprocessed items
    indices.sort((a, b) => b - a);

    return {
      ...rest,
      ...getReducedDataWithMergedCounts(dataResponses, indices),
      ...getReducedDataWithMergedPercentages(dataPercentages, indices)
    };
  });

const getIndicesOfAnswersUnderOnePercent = ({ dataPercentages }) =>
  dataPercentages.flatMap(({ count: percent }, i) => (percent < 1 ? [i] : []));

const getReducedDataWithMergedCounts = (dataResponses, indicesToMerge) => {
  const { data, otherAnswersTooltip } = getReducedDataWithToolTip(
    dataResponses,
    (answer, count) => `${answer}: ${count}`,
    (i) => i,
    indicesToMerge
  );
  return { dataResponses: data, otherAnswersTooltip };
};

const getReducedDataWithMergedPercentages = (
  dataPercentages,
  indicesToMerge
) => {
  const { data, otherAnswersTooltip } = getReducedDataWithToolTip(
    dataPercentages,
    (answer, count) => `${answer}: ${count}%`,
    (i) => i.toFixed(2),
    indicesToMerge
  );
  return {
    dataPercentages: data,
    otherAnswersToolTipPercentage: otherAnswersTooltip
  };
};

const getReducedDataWithToolTip = (
  data,
  tooltipItemFormatter,
  displayedValueFormatter,
  indicesToMerge
) => {
  const filterMask = new Set(indicesToMerge);
  const mergedResponses = data.filter((_, i) => filterMask.has(i));
  const countForOther = mergedResponses.reduce(
    (acc, { count }) => acc + count,
    0
  );
  const otherAnswersTooltip = mergedResponses.map(({ answer, count }) =>
    tooltipItemFormatter(answer, count)
  );
  const dataResponses = [
    ...data.filter((_, i) => !filterMask.has(i)),
    { answer: 'Other', count: displayedValueFormatter(countForOther) }
  ];
  return {
    data: dataResponses,
    otherAnswersTooltip
  };
};
