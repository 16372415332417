import soeTeamData from 'api/team/soeTeamData.json';
import wapsTeamData from 'api/team/wapsTeamData.json';
import useIsMobile from 'util/hooks/useIsMobile';
import Heading from './Heading';

import TeamMember from './TeamMember';
import clsx from 'clsx';

const TeamList = () => {
  const { general, development } = soeTeamData;
  const { development: wapsDevTeam, general: wapsGeneral } = wapsTeamData;
  const isMobile = useIsMobile();

  const isLastRowName = (name) =>
    ['Engineering HIVE ambassadors', 'Egert Teesaar'].includes(name);

  return (
    <div className='w-full min-h-screen pt-20 pb-3 sm:pb-18 px-4 flex flex-col justify-between page-background'>
      <div className='max-w-5xl m-auto px-4 bg-white border border-slate-300 rounded-md shadow-xl'>
        <div className='p-8'>
          <Heading className='py-3 text-2xl md:text-3xl lg:text-4xl font-bold text-center text-black'>
            Nortal State of Engineering 2023 team
          </Heading>
          <div className='grid gap-y-8 grid-cols-1 lg:grid-cols-3 my-4'>
            {general.map(
              ({ name, role }, idx) =>
                !isLastRowName(name) && (
                  <TeamMember name={name} role={role} key={`general-${idx}`} />
                )
            )}
            <div className='lg:col-span-3'>
              <div className='grid gap-y-8 grid-cols-1 lg:grid-cols-2 sm:px-32'>
                {general.map(
                  ({ name, role }, idx) =>
                    isLastRowName(name) && (
                      <TeamMember
                        name={name}
                        role={role}
                        key={`general-${idx}`}
                      />
                    )
                )}
              </div>
            </div>
          </div>
          <Heading className='py-3 text-xl lg:text-2xl font-bold text-center text-black'>
            Form and results page development
          </Heading>
          <div className='grid gap-y-8 grid-cols-1 lg:grid-cols-3 my-4'>
            {development.map(({ name, role }, idx) => (
              <TeamMember
                name={name}
                role={role}
                key={`dev-${idx}`}
                className={
                  !isMobile && idx === development.length - 1 && 'col-span-3'
                }
              />
            ))}
          </div>
        </div>
        <div className='px-8 pb-8'>
          <Heading className='py-3 text-2xl md:text-3xl lg:text-4xl font-bold text-center text-black'>
            Well Architected Projects Team
          </Heading>
          <div className='grid lg:grid-cols-3 grid-cols-1 my-6'>
            {wapsGeneral.map(({ name, role }, idx) => {
              const orderClasses = clsx({
                'order-first lg:order-none': role === 'Product owner'
              });
              return (
                <TeamMember
                  name={name}
                  role={role}
                  key={`waps-general-${idx}`}
                  className={orderClasses}
                />
              );
            })}
          </div>
          <div className='grid lg:grid-cols-2 grid-cols-1'>
            {wapsDevTeam.map(({ name, role }, idx) => (
              <TeamMember name={name} role={role} key={`waps-dev-${idx}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamList;
