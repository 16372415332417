import { useMsal } from '@azure/msal-react';
import questionsData2023 from 'api/ehc/waps/2023/questionsData.json';
import sharePointItemsJson2023 from 'api/ehc/waps/2023/sharePointItemsData.json';
import questionsData2024 from 'api/ehc/waps/2024/questionsData.json';
import sharePointItemsJson2024 from 'api/ehc/waps/2024/sharePointItemsData.json';
import surveyMetadata from 'api/ehc/waps/surveyMetaData.json';
import Hero from 'components/Hero';
import ResultsCard from 'components/ResultsCard';
import ScrollToTopButton from 'components/ScrollToTopButton';
import { getQuestionsWithAnswersForGeneralView } from 'questionsProvider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const options = [
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' }
];

const ProjectResponseGeneralView = () => {
  const { year } = useParams();
  const selectedYear = year || '2024';
  const { surveyName, description } = surveyMetadata;
  const { instance, accounts } = useMsal();
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    options.find((op) => op.value === selectedYear)
  );

  const questionItems =
    selectedOption.value === '2024'
      ? sharePointItemsJson2024
      : sharePointItemsJson2023;
  const questionsData =
    selectedOption.value === '2024' ? questionsData2024 : questionsData2023;

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ['user.read'],
      account: accounts[0]
    };

    instance.acquireTokenSilent(accessTokenRequest).then(async () => {
      const questionsWithAnswers = getQuestionsWithAnswersForGeneralView({
        questionItems: questionItems,
        questionsData: questionsData,
        aggregateResponders: true
      });
      setQuestionsWithAnswers(questionsWithAnswers);
    });
  }, [instance, accounts, questionItems, questionsData]);

  return (
    <div className='page-background h-full flex flex-col'>
      <Hero title={surveyName} description={description} />

      <Select
        className='w-[22rem] lg:w-[6rem] mx-auto mb-4'
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
      />

      <ResultsCard
        questionsWithAnswers={questionsWithAnswers}
        questionItems={questionItems}
        year={selectedOption.value}
      />

      <ScrollToTopButton />
    </div>
  );
};

export default ProjectResponseGeneralView;
