import clsx from 'clsx';

const Heading = ({
  className,
  children,
  level = 1,
  fontWeight = 'regular',
  id = ''
}) => {
  const otherProps = { ...(id && { 'data-testid': id, id }) };
  const classes = clsx('text-nortal-grey-dark font-bold', {
    'font-roboto-bold': fontWeight === 'bold',
    'font-roboto-black': fontWeight === 'black',
    'font-roboto-regular': fontWeight === 'regular',
    'text-lg': level === 4,
    'text-2xl': level === 3,
    'text-3xl': level === 2,
    'sm:text-[3.125rem] sm:leading-[3.75rem] text-3xl': level === 1,
    [className]: !!className
  });

  return (
    <h1 className={classes} {...otherProps}>
      {children}
    </h1>
  );
};

export default Heading;
