import doraReport from 'api/ehc/doraReport.json';

import Heading from 'components/Heading';
import Hero from 'components/Hero';
import PlainTextBlock from 'components/PlainTextBlock';
import ScrollToTopButton from 'components/ScrollToTopButton';

const DORAMetrics = () => {
  return (
    <div className='page-background h-full'>
      <Hero title={doraReport.title} description={doraReport.summary} />
      <div className='flex flex-col container mx-auto w-[22rem] lg:w-[61rem]'>
        <div className='flex flex-col gap-6 bg-white rounded-lg border lg:px-12 px-4 py-10 my-6'>
          <div>
            <Heading level={3} fontWeight='black'>
              Table of contents
            </Heading>
            <ul className='px-4 flex flex-col text-nortal-green text-lg list-disc py-4'>
              {doraReport.paragraphs.map(({ title }, idx) => (
                <li key={`paragraph-${idx}`}>
                  <a className='hover:underline' href={`#paragraph-${idx}`}>
                    {title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {doraReport.paragraphs.map(({ title, richTextBody }, idx) => (
            <PlainTextBlock
              question={title}
              answer={richTextBody}
              id={`paragraph-${idx}`}
              key={`paragraph-${idx}`}
            />
          ))}
        </div>
      </div>
      <ScrollToTopButton />
    </div>
  );
};

export default DORAMetrics;
